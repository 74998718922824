.contact {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

a {
  font-size: 1.4rem;
}

.subHeading {
  margin: 0;
}
