.video-responsive {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-height: 480px;
  max-width: 853px;
  height: 90%;
  width: 90%;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99;
}

.youtubeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.9);
}

.hide {
  display: none;
}

.close {
  color: white;
  margin-bottom: 30px;
}

.close:hover {
  color: darkred;
}
